.App {
  text-align: center;
}

.App-logo {
  height: 7vh;
  margin-right: 1rem;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  margin-top: 0.5rem;
}

.Disclaimer {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: gray;
  margin-top: 0.5rem;
  padding-left: 25%;
  padding-right: 25%;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .Disclaimer {
    padding-left: 5%;
    padding-right: 5%;
  }
}
